.transform-left-right-50 {
  transform: translate(-50%, -30%);
}
.mantine-Select-input,
.mantine-TextInput-input,
.mantine-PasswordInput-input {
  height: 44px !important;
}
.asideBar ::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.asideBar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(172, 171, 171);
  border-radius: 10px;
}

/* Handle */
.asideBar ::-webkit-scrollbar-thumb {
  background: rgb(153, 152, 152);
  border-radius: 10px;
}

/* Handle on hover */
.asideBar ::-webkit-scrollbar-thumb:hover {
  background: rgb(119, 119, 119);
}

.mantine-Modal-inner ::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.mantine-Modal-inner ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(172, 171, 171);
  border-radius: 10px;
}

/* Handle */
.mantine-Modal-inner ::-webkit-scrollbar-thumb {
  background: rgb(153, 152, 152);
  border-radius: 10px;
}

/* Handle on hover */
.mantine-Modal-inner ::-webkit-scrollbar-thumb:hover {
  background: rgb(119, 119, 119);
}

/* Login Module */
@media screen and (min-width: 500px) {
  .w-login {
    width: 450px;
  }
}
.login .mantine-TextInput-label,
.login .mantine-PasswordInput-label,
.login .mantine-Checkbox-label {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: var(---text-lightBlack) !important;
}

.login .mantine-TextInput-input,
.login .mantine-PasswordInput-innerInput {
  height: 44px;
  font-size: 14px;
  padding: 10px;
  margin: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.rounded-lg .mantine-Input-input {
  border-radius: 8px;
  /* height: 44px; */
}
.NumberInput input {
  height: 44px !important;
}
.NumberInput .mantine-NumberInput-section[data-position="right"] {
  display: none;
}
.addUser .mantine-Input-input {
  height: 44px;
}
.mobileSelect .mantine-Input-input {
  height: 44px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.mobileInput input {
  border-left: none !important;
}
.p-0 {
  padding: 0 !important;
}
.border-b-1 {
  border-bottom: 1px solid #eaecf0;
}
.lineheight-none {
  line-height: unset;
}
.w-180 {
  width: 180px;
}

/* body{
  padding-right: 0px !important;
} */
.TablePopover .MuiPaper-rounded {
  width: 150px !important;
  /* box-shadow: none !important; */
  box-shadow: 0px 12px 16px -4px #10182814 !important;
}
.addvcuser_boxshadow {
  box-shadow: 0px 1px 3px 0px #1018281a;
}
.vcUser .MuiBox-root {
  border-radius: 16px;
  border: 1px solid #eaecf0;
}
.addVcuserCheckboxlabel .mantine-Checkbox-label {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: var(---text-lightBlack) !important;
}
.addVcuserCheckboxlabel .mantine-Checkbox-inner .mantine-Checkbox-input {
  border-color: #ff0707 !important;
  background-color: #ffffff !important;
}

.addVcuserCheckboxlabel .mantine-Checkbox-inner .mantine-Checkbox-icon {
  color: #ff0909 !important;
}

.addvsUserSalary .mantine-Select-input {
  border-left: none;
  height: 44px;
  border-radius: 0px 10px 10px 0px;
}
.addvsUserSalary .mantine-Select-input:focus,
.addvsUserSalary .mantine-TextInput-input:focus {
  outline: none;
  --input-bd: #ced4da;
}
.addvsUserSalary .mantine-TextInput-input {
  border-right: none;
  border-radius: 10px 0px 0px 10px;
}

.addvcsuerModal .mantine-Modal-title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: var(---text-black) !important;
  font-size: 18px;
}
.w-52px {
  width: 52px !important;
}

.tablePopover .mantine-Popover-dropdown {
  width: 150px !important;
}
.reportDatePicker button {
  height: 44px;
}

.raisenewRequest .mantine-InputWrapper-label {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: var(---text-lightBlack) !important;
}

.rolesTable .mantine-datatable-empty-state,
.mantine-datatable-loader,
.mantineTableWeekly .mantine-datatable-empty-state {
  display: none !important;
}

.rolesTable .mantine-datatable-table > thead > tr > th {
  height: 50px !important;
}
.rolesTable .mantine-datatable-table > tbody > tr > td {
  height: 50px !important;
}

.rolesTable thead,
.mantineTableWeekly thead {
  background: #f9fafb;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: var(---text-greydes) !important;
}
.rolesTable .mantine-datatable-header-cell-sortable-text,
.mantineTableWeekly .mantine-datatable-header-cell-sortable-text {
  font-size: 12px !important;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: var(---text-greydes) !important;
}
.rolesTable .mantine-focus-auto:checked {
  background-color: #fff4ed !important;
  border-color: #e62e05 !important;
}

.rolesTable .mantine-focus-auto[data-indeterminate] {
  background-color: #fff4ed !important;
  border-color: #e62e05 !important;
}
.rolesTable .mantine-Checkbox-icon {
  color: #e62e05 !important;
}
.rolesTable .mantine-datatable-header-selector-cell {
  width: 40px !important;
}
.mantine-datatable-row-expansion-cell {
  padding: 0px !important;
}

.mantineTableWeeklySub > div > div > div > table > thead {
  display: none !important;
}
.mantineTableWeekly .mantine-datatable-table > tbody > tr > td {
  height: 60px !important;
}
.mantineTableWeekly .mantine-datatable-table > thead > tr > th {
  height: 50px !important;
}

.mantineTableWeekly .mantine-datatable-table > thead > tr > th > div > div {
  justify-content: flex-end;
}
.dailyCostCompByShow .mantine-datatable-table > thead > tr > th > div > div {
  justify-content: flex-end;
}
.dailyCostCompByShow div[title*="Expense Head"] {
  justify-content: left !important;
  text-align: start !important;
}
.mantineTableWeekly div[title*="Expense Head"] {
  width: 200px !important;
  text-align: start !important;
}
.mantine-datatable-header-selector-cell {
  background: #f9fafb !important;
}
.mantine-datatable-row-selector-cell {
  background: white !important;
}

/* DeleteModal */

.deleteModal .mantine-Modal-header {
  display: none;
}
.deleteModal .mantine-Modal-content {
  border-radius: 12px;
}
.addProductDropZone {
  border-radius: 12px !important;
  border: 1px solid #eaecf0 !important;
}
.addProduct-drawer .mantine-Drawer-title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: var(---text-black) !important;
  font-size: 18px;
}
.address-min-h-40 textarea {
  min-height: 160px;
}
.mantine-MultiSelect-inputField {
  min-height: 30px;
}
/* .mantine-Popover-dropdown{
  width: max-content !important;
} */
.mantine-Input-input[readonly] {
  background-color: #f9fafb;
}
.productDeleteIcon {
  right: -25px;
  top: -10px;
  height: 30px;
  cursor: pointer;
}
.mantine-Switch-input:checked + .mantine-Switch-track {
  --switch-bg: var(---text-orange) !important;
  --switch-bd: var(---text-orange) !important;
}
.manageUnits_toggle .mantine-Switch-label {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: var(---text-black) !important;
}
.min-h-11 .ant-select-selector {
  min-height: 44px;
}
.mantine-Switch-track {
  cursor: pointer !important;
}
.loadMore button[disabled] {
  background: #f9fafb;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #e62e05 !important;
  background-color: #fff4ed !important;
}
.ant-radio-checked .ant-radio-inner::after {
  background: #e62e05 !important;
}
.dailyReportsDrawer header {
  display: none;
}
.withoutCalenderIcon input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.border-red {
  border: 1px solid #f04438;
}
.recharts-yAxis .recharts-cartesian-axis-tick text {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: var(---text-lightBlack) !important;
}
.recharts-xAxis .recharts-cartesian-axis-tick text {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: var(---text-greydes) !important;
}
.mantine-BarChart-axisLabel {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  fill: var(---text-greydes) !important;
}
.todaySpends .mantine-BarChart-axisLabel {
  transform: translateX(-65px) translateY(100px) rotate(-90deg);
}
.topFiveVc .mantine-BarChart-axisLabel {
  transform: translateX(-215px) translateY(200px) rotate(-90deg);
}
.chartXLable {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: var(---text-greydes) !important;
  font-size: 12px;
}
.justify-center {
  justify-content: center !important;
  display: flex !important;
}
.videotag::-webkit-media-controls-volume-slider {
  display: none !important;
}
.videotag::-webkit-media-controls-mute-button {
  display: none !important;
}
.mantine-datatable-text-align-center{
  text-align: center !important;
}
body {
  margin: 0;
  font-family: 'inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer mantine, mantine-datatable;

:root {


  /* font Size */
  ---font-size-medium: 16px;
  ---font-size-fit: 14px;
  ---text-lightBlack:#344054;
  ---text-black:#101828;
  ---text-white:#FFFFFF;
  ---text-greydes:#475467;
  ---input-grey:#667085;
  ---text-darkBrown:#97180C;
  ---text-redBrown:#BC1B06;
  ---text-violet:#3538CD;
  ---text-brown:#B42318;
  ---text-green:#067647;
  ---text-blue:#363F72;
  ---card-text-balck:#1D2939;
  ---text-orange:#E62E05;
}

.inter-400-344054 {
  font-family: "Inter", sans-serif;
  font-weight:400;
  color: var(---text-lightBlack) !important;
}

.inter-500-344054 {
  font-family: "Inter", sans-serif;
  font-weight:500;
  color: var(---text-lightBlack) !important;
}


.inter-600-344054 {
  font-family: "Inter", sans-serif;
  font-weight:600;
  color: var(---text-lightBlack) !important;
}

.inter-600-101828 {
  font-family: "Inter", sans-serif;
  font-weight:600;
  color: var(---text-black) !important;
}


.inter-500-101828 {
  font-family: "Inter", sans-serif;
  font-weight:500;
  color: var(---text-black) !important;
}

.inter-600-FFFFFF {
  font-family: "Inter", sans-serif;
  font-weight:600;
  color: var(---text-white) !important;
}

.inter-400-475467 {
  font-family: "Inter", sans-serif;
  font-weight:400;
  color: var(---text-greydes) !important;
}
.inter-500-475467 {
  font-family: "Inter", sans-serif;
  font-weight:500;
  color: var(---text-greydes) !important;
}

.inter-600-475467 {
  font-family: "Inter", sans-serif;
  font-weight:600;
  color: var(---text-greydes) !important;
}

.inter-500-667085 {
  font-family: "Inter", sans-serif;
  font-weight:500;
  color: var(---input-grey) !important;
}

.inter-600-667085 {
  font-family: "Inter", sans-serif;
  font-weight:600;
  color: var(---input-grey) !important;
}

.inter-500-667085-sb {
  font-family: "Inter", sans-serif;
  font-weight:500;
  color: var(---input-grey);
}

.inter-400-667085 {
  font-family: "Inter", sans-serif;
  font-weight:400;
  color: var(---input-grey) !important;
}

.inter-500-97180C {
  font-family: "Inter", sans-serif;
  font-weight:500;
  color: var(---text-darkBrown) !important;
}

.inter-400-97180C {
  font-family: "Inter", sans-serif;
  font-weight:400;
  color: var(---text-darkBrown) !important;
}

.inter-600-BC1B06 {
  font-family: "Inter", sans-serif;
  font-weight:600;
  color: var(---text-redBrown) !important;
}

.inter-500-3538CD {
  font-family: "Inter", sans-serif;
  font-weight:500;
  color: var(---text-violet) !important;
}

.inter-500-B42318 {
  font-family: "Inter", sans-serif;
  font-weight:500;
  color: var(---text-brown) !important;
}

.inter-500-067647 {
  font-family: "Inter", sans-serif;
  font-weight:500;
  color: var(---text-green) !important;
}

.inter-500-363F72 {
  font-family: "Inter", sans-serif;
  font-weight:500;
  color: var(---text-blue) !important;
}

.inter-600-1D2939 {
  font-family: "Inter", sans-serif;
  font-weight:600;
  color: var(---card-text-balck) !important;
}

.inter-500-1D2939 {
  font-family: "Inter", sans-serif;
  font-weight:500;
  color: var(---card-text-balck) !important;
}
.inter-500-E62E05{
  font-family: "Inter", sans-serif;
  font-weight:500;
  color: var(---text-orange) !important;
}
.inter-500-B54708{
  font-family: "Inter", sans-serif;
  font-weight:500;
  color: #B54708 !important;
}